import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme } from 'app/theme'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  className?: string
  id?: string
  languageCode?: string
  languagePrefix?: string | null
  target?: string
  title: string
  url?: string
}

export const MenuItem = memo(function MenuItem({
  className,
  target,
  title,
  url,
}: Props) {
  if (!title || !url) {
    return null
  }

  return (
    <>
      {target ? (
        <ExternalLink
          className={className}
          href={url}
          rel="noopener"
          target={target}
        >
          {title}
        </ExternalLink>
      ) : (
        <Anchor className={className} to={url} activeClassName="active">
          {title}
        </Anchor>
      )}
    </>
  )
})

const Style = css`
  color: ${theme.colors.variants.neutralLight4};
  font-family: ${theme.fontFamily.paragraph};
  font-size: 0.75rem;
  line-height: 1.5rem;
  padding-bottom: 0.4375rem;
  position: relative;
  &.active,
  &:hover {
    &:after {
      right: 0;
    }
  }
  &:after {
    content: '';
    height: 0.0625rem;
    background: ${theme.colors.variants.neutralLight4};
    position: absolute;
    bottom: 0;
    right: 100%;
    left: 0;
    transition: 0.2s ease-in-out;
  }

  @media (max-width: 1199px) {
    color: ${theme.colors.variants.neutralDark4};
    font-family: ${theme.fontFamily.heading};
    font-size: 1.5rem;
    line-height: 2rem;
    padding-bottom: 0;
    &:after {
      background: ${theme.colors.variants.neutralDark4};
    }
  }
`

const ExternalLink = styled.a`
  ${Style}
`

const Anchor = styled(Link)`
  ${Style}
`
