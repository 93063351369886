import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme/index'
import React, { memo } from 'react'

export interface Props {
  onClick: () => void
  variant?: Variant
}

export const Close = memo(function Close({
  onClick,
  variant = 'default',
}: Props) {
  return (
    <Container dial={5} row onClick={onClick} variant={variant}>
      <Icon className="close-icon" />
    </Container>
  )
})

const Container = styled(FlexBox)<ContainerProps>`
  cursor: pointer;
  position: fixed;
  top: 1.875rem;
  right: 1.875rem;
  transition: 0.6s ease-in-out;
  z-index: 3;
  &:hover {
    .close-icon {
      &:before,
      &:after {
        transform: none;
      }
    }
  }

  ${({ variant }) => {
    switch (variant) {
      case 'secondary':
        return css`
          right: 6rem;
          .close-icon {
            &:before,
            &:after {
              background: ${theme.colors.variants.secondaryDark};
            }
          }

          @media (max-width: 1199px) {
            right: 1.875rem;
          }
        `
    }
  }}
`

const Icon = styled.div`
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  position: relative;
  &:before,
  &:after {
    content: '';
    width: 1.75rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.primaryDark};
    border-radius: 1.25rem;
    position: absolute;
    top: 0.5625rem;
    left: -0.25rem;
    transition: 0.2s ease-out;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'secondary'
