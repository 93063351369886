import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { HeaderContext } from 'app/containers/Header'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo, useContext, useEffect, useState } from 'react'

export const Toolbars = memo(function Toolbars() {
  const { IBEURL, languageCode, languagePrefix, quotationPageURL } = useContext(
    HeaderContext,
  ) as any

  const quotationPageSlug = quotationPageURL?.find(
    (t: any) => t?.languages_code?.code === languageCode,
  )?.slug

  const quotationRealPageURL = languagePrefix
    ? `/${languagePrefix}/${quotationPageSlug}`
    : `/${quotationPageSlug}`

  const [scrollDir, setScrollDir] = useState('up')

  useEffect(() => {
    const threshold = 0
    let lastScrollY = window.pageYOffset
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }
      setScrollDir(scrollY > lastScrollY ? 'down' : 'up')
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollDir])

  return (
    <Container
      className={scrollDir == 'down' ? 'hidden' : ''}
      dial={5}
      row
      stretch
    >
      {IBEURL ? (
        <Button
          className="header-book"
          icon="calendar"
          label={useVocabularyData('book', languageCode)}
          URL={`${IBEURL}&lang=${languagePrefix || 'it'}`}
          rel="noopener"
          target="_blank"
        />
      ) : null}
      {quotationPageURL ? (
        <QuotationCTA
          label={useVocabularyData('ask-for', languageCode)}
          icon="mail"
          URL={quotationRealPageURL}
          variant="primary"
        />
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  /* box-shadow: ${rgba(theme.colors.variants.neutralDark1, 0.12)} 0 2px 8px; */
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 50;
  text-transform: uppercase;
  transition: 0.3s ease-out;
  &.hidden {
    transform: translateY(6rem);
  }

  a {
    width: 50%;
    background: ${({ theme }) => theme.colors.variants.secondaryLight};
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    font-size: 0.8125rem;
    letter-spacing: 0.0875rem;
    line-height: 1.25rem;
    padding: 1.25rem 0;
    text-align: center;
    &:last-of-type {
      background: ${({ theme }) => theme.colors.variants.primaryLight1};
      color: ${({ theme }) => theme.colors.variants.neutralLight4};
    }
  }
`

const QuotationCTA = styled(Button)``
