import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { memo } from 'react'

export interface Props {
  className?: string
  variant?: Variant
}

export const Spinner = memo(function Spinner({
  className,
  variant = 'default',
}: Props) {
  return <Container className={className} variant={variant} />
})

const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
  background: ${rgba(theme.colors.variants.neutralLight2, 0.8)};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  transition: 0.2s;
  &.hidden {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }
  &:before {
    content: '';
    width: 3rem;
    height: 3rem;
    background: none;
    border: 0.25rem solid ${({ theme }) => theme.colors.variants.primaryLight2};
    border-top-color: transparent;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -1.25rem 0 0 -1.25rem;
    animation: spin 1s infinite linear;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'simple':
        return css`
          background: none;
          z-index: 1;
          &:before {
            border: 0.25rem solid ${theme.colors.variants.neutralLight4};
            border-top-color: transparent;
          }
        `
      case 'dark':
        return css`
          background: none;
          z-index: 1;
          &:before {
            border: 0.25rem solid ${theme.colors.variants.primaryLight2};
            border-top-color: transparent;
          }
        `
      case 'newsletter':
        return css`
          background: ${rgba(theme.colors.variants.primaryDark, 0.8)};
          &:before {
            border: 0.25rem solid ${theme.colors.variants.neutralLight4};
            border-top-color: transparent;
          }
        `
    }
  }}

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'simple' | 'dark' | 'newsletter'
