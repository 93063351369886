import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { compact, uniqBy } from 'lodash'
import React, { memo } from 'react'

import { MenuItem, Props as MenuItemProps } from './MenuItem'

export interface Props {
  languageCode: string
  linkFooterLabel?: string
  linkFooterURL?: string
  policyMenu?: { [key: string]: MenuItemProps[] }[]
  vat?: string
}

export const Bottom = memo(function Bottom({
  languageCode,
  linkFooterLabel,
  linkFooterURL,
  policyMenu,
  vat,
}: Props) {
  const policyNav = compact(
    compact(policyMenu?.map((lang) => lang[languageCode as 'it-IT']))[0],
  ).filter((t) => t.languageCode === languageCode)

  return (
    <Container row space="between" wrap>
      {vat ? (
        <Vat>{`${useVocabularyData('vat', languageCode)} ${vat}`}</Vat>
      ) : null}

      <Wrapper>
        {policyMenu ? (
          <Policy dial={2} inline row wrap>
            {uniqBy(policyNav, 'title').map((item, index) => (
              <MenuItem key={index} {...item} />
            ))}
          </Policy>
        ) : null}

        <IubendaLink
          href={`https://www.iubenda.com/privacy-policy/${
            languageCode === 'it-IT' ? 32072552 : 79085192
          }`}
          className="iubenda-white iubenda-noiframe iubenda-embed iub-legal-only iubenda-noiframe"
          title="Privacy Policy"
        >
          Privacy Policy
        </IubendaLink>

        <IubendaLink
          href={`https://www.iubenda.com/privacy-policy/${
            languageCode === 'it-IT' ? 32072552 : 79085192
          }/cookie-policy`}
          className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
          title="Cookie Policy"
        >
          Cookie Policy
        </IubendaLink>

        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);`,
          }}
        />
        <CustomLink href={linkFooterURL} target="_blank" rel="noopener">
          {linkFooterLabel}
        </CustomLink>
        <Credits
          aria-label="QNT Hospitality"
          href="https://www.qnt.it"
          rel="noopenet"
          target="_blank"
        >
          produced by <b>QNT HOSPITALITY</b>
        </Credits>
      </Wrapper>
    </Container>
  )
})

const Container = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  line-height: 1.5rem;
  opacity: 0.4;
  margin-top: 4.6875rem;
  padding: 0 11.944vw;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    margin-top: 6.25rem;
    text-align: center;
  }
`

const Vat = styled.span`
  @media (max-width: 1023px) {
    margin-bottom: 1.625rem;
    width: 100%;
  }
`

const Wrapper = styled.div`
  .iubenda-embed:not(.no-brand):not(.iubenda-nostyle) {
    background: none !important;
    color: ${({ theme }) => theme.colors.variants.neutralLight4} !important;
    font-family: ${({ theme }) => theme.fontFamily.paragraph} !important;
    font-size: 0.75rem !important;
    font-weight: 400 !important;
    line-height: 1.5rem !important;
    margin-right: 1.25rem !important;
    padding: 0 !important;
    text-transform: uppercase !important;
    vertical-align: top !important;
    box-shadow: none !important;
  }

  @media (max-width: 1023px) {
    margin: 0 auto;
  }
`

const Policy = styled(FlexBox)`
  @media (max-width: 1023px) {
    width: 100%;
    margin: 0 auto;

    > a {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
`

const CustomLink = styled.a`
  margin-right: 1.25rem;
`

const IubendaLink = styled.a``

const Credits = styled.a``
