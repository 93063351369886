import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme } from 'app/theme/index'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  id?: string
  title: string
  languageCode?: string
  languagePrefix?: string | null
  URL: string
}

export const MenuItem = memo(function MenuItem({ title, URL }: Props) {
  if (!title || !URL) {
    return null
  }

  return (
    <Container activeClassName="active" to={URL}>
      {title}
    </Container>
  )
})

const Container = styled(Link)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin-right: 1.875rem;
  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 1023px) {
    margin-right: 0;
  }
`
