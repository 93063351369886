import styled from '@emotion/styled'
import { HeaderContext } from 'app/containers/Header'
import { theme } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo, useContext } from 'react'

import { MenuItem, Props as MenuItemProps } from './MenuItem'

export interface Props {
  isSticky?: boolean
  menuItems: MenuItemProps[]
  onClick?: () => void
}

export const Navigation = memo(function Navigation({
  isSticky,
  menuItems,
}: Props) {
  if (!menuItems) {
    return null
  }

  const { IBEURL, languageCode, languagePrefix } = useContext(
    HeaderContext,
  ) as any

  return (
    <Container className={isSticky ? 'sticky' : undefined}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Nav>
          {menuItems.map((item, index) => (
            <List key={index}>
              <MenuItem {...item} />
            </List>
          ))}

          <Media lessThan="desktopSmall">
            <Anchor
              href={`${IBEURL}&lang=${languagePrefix || 'it'}`}
              rel="noopener"
              target="_blank"
            >
              {useVocabularyData('book', languageCode)}
            </Anchor>
          </Media>
        </Nav>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.nav`
  &.sticky {
    a {
      color: ${({ theme }) => theme.colors.variants.neutralDark4};
      &:after {
        background: ${({ theme }) => theme.colors.variants.neutralDark4};
      }
    }
  }

  @media (max-width: 1199px) {
    display: block;
    margin-top: 5.625rem;
    margin-left: auto;
    margin-bottom: 1.5rem;
    text-align: center;
    transform: translateX(0);

    li {
      margin-top: 1.5rem;
      margin-right: 0;
    }
  }
`

const Nav = styled.ul`
  display: flex;

  @media (max-width: 1199px) {
    flex-direction: column;
    justify-content: center;
  }
`

const List = styled.li`
  margin-right: 1.625rem;

  @media (min-width: 1200px) {
    &:last-of-type {
      margin-right: 0;
    }
  }

  @media (max-width: 1339px) {
    margin-right: 1.5rem;
  }
`

const Anchor = styled.a`
  display: block;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.9375rem;
  line-height: 1.5rem;
  padding-bottom: 0.4375rem;
  position: relative;
  margin-top: 1.5rem;

  @media (max-width: 1339px) {
    font-size: 0.875rem;
  }

  @media (max-width: 1199px) {
    color: ${theme.colors.variants.neutralDark4};
    font-size: 1.5rem;
    line-height: 2rem;
  }
`
