import styled from '@emotion/styled'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { NewsletterFormSender } from 'app/utils/NewsletterFormSender'
import { compact, uniqBy } from 'lodash'
import React, { memo, useMemo } from 'react'

import { Bottom } from './Bottom'
import { Contacts } from './Contacts'
import { Hotels } from './Hotels'
import { MenuItem, Props as MenuItemProps } from './MenuItem'
import { Newsletter } from './Newsletter'
import { Social } from './Social'

export interface Props {
  address?: string
  email?: string
  facebookURL?: string
  hotels?: any
  instagramURL?: string
  youtubeURL?: string
  languageCode: string
  linkedinURL?: string
  linkFooterLabel?: string
  linkFooterURL?: string
  logo?: string
  newsletterImage?: ImageProps
  phone?: string
  policyMenu?: { [key: string]: MenuItemProps[] }[]
  secondaryMenu?: { [key: string]: MenuItemProps[] }[]
  siteName?: string
  vat?: string
  variant?: Variant
}

export const Footer = memo(function Footer({
  address,
  email,
  facebookURL,
  hotels,
  instagramURL,
  youtubeURL,
  languageCode,
  linkedinURL,
  linkFooterLabel,
  linkFooterURL,
  logo,
  newsletterImage,
  phone,
  policyMenu,
  secondaryMenu,
  siteName,
  vat,
  variant = 'default',
}: Props) {
  const sendFormTo = useMemo(
    () =>
      NewsletterFormSender({
        formURL: 'https://a1e5x4.emailsp.com/frontend/subscribe.aspx',
      }),
    [],
  )

  const secondaryNav = compact(
    compact(secondaryMenu?.map((lang) => lang[languageCode as 'it-IT']))[0],
  ).filter((t) => t.languageCode === languageCode)

  return (
    <Container>
      {variant === 'default' ? (
        <>
          <Newsletter
            image={newsletterImage}
            languageCode={languageCode}
            onNewsletterFormSendTo={async (data) => await sendFormTo(data)}
          />

          <Hotels languageCode={languageCode} list={hotels} />
        </>
      ) : null}

      <Wrapper row space="between" wrap>
        <LeftSide>
          {logo ? (
            <Logo alt={siteName} src={logo} width="214" height="40" />
          ) : null}

          <Contacts
            address={address}
            email={email}
            languageCode={languageCode}
            phone={phone}
          />
        </LeftSide>
        <RightSide>
          {secondaryMenu ? (
            <Menu dial={3} row wrap>
              {uniqBy(secondaryNav, 'title').map((item, index) => (
                <MenuItem key={index} {...item} />
              ))}
            </Menu>
          ) : null}

          <Social
            facebookURL={facebookURL}
            instagramURL={instagramURL}
            youtubeURL={youtubeURL}
            linkedinURL={linkedinURL}
          />
        </RightSide>
      </Wrapper>

      <Bottom
        languageCode={languageCode}
        policyMenu={policyMenu}
        vat={vat}
        linkFooterLabel={linkFooterLabel}
        linkFooterURL={linkFooterURL}
      />
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.primaryDark};
  padding-bottom: 2.8125rem;
`

const Wrapper = styled(FlexBox)`
  padding: 4.5rem 11.944vw 0;

  @media (max-width: 1023px) {
    padding: 3.75rem 3.75rem 0;
  }
`

const LeftSide = styled.div`
  @media (max-width: 1023px) {
    margin: 0 auto;
    text-align: center;
  }
`

const Logo = styled.img``

const RightSide = styled.div`
  @media (max-width: 1023px) {
    margin: 0 auto;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
`

const Menu = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2.125rem;
  text-transform: uppercase;

  a {
    &:last-of-type {
      margin-right: 0;
    }
  }

  @media (max-width: 1023px) {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
      display: block;
    }
  }
`

export type Variant = 'default' | 'simple'
