import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  memo,
} from 'react'

import { Option, Props as OptionProps } from './Option'

export interface Props {
  className?: string
  color?: Color
  disabledFirst?: string
  name: string
  error?: boolean
  isDirty?: boolean
  options: OptionProps[]
  required?: boolean
  defaultValue?: string
  onBlur?: FocusEventHandler<HTMLSelectElement>
  onChange?: ChangeEventHandler<HTMLSelectElement>
  onFocus?: FocusEventHandler<HTMLSelectElement>
  value?: string
  variant?: Variant
}
export const Select = memo(
  forwardRef<HTMLSelectElement, Props>(function Select(
    {
      className,
      color = 'default',
      disabledFirst,
      name,
      error = false,
      isDirty = false,
      options,
      required,
      defaultValue,
      onBlur,
      onChange,
      onFocus,
      value,
      variant = 'default',
    },
    ref,
  ) {
    return (
      <Container
        ref={ref}
        color={color}
        error={error}
        className={className}
        isDirty={isDirty}
        name={name}
        required={required}
        defaultValue={defaultValue}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
        variant={variant}
      >
        {disabledFirst ? <Option value={disabledFirst} disabled /> : null}
        {options.map((item, index) => (
          <>
            <Option key={index} {...item} />
          </>
        ))}
      </Container>
    )
  }),
)

Select.displayName = 'Select'

const Container = styled.select<ContainerProps>`
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  cursor: pointer;

  ${({ theme, color, error, variant }) => {
    switch (variant) {
      case 'default':
        return css`
          height: 3.5rem;
          background-color: ${error
            ? theme.colors.variants.dangerLight
            : color === 'default'
            ? theme.colors.variants.neutralLight4
            : theme.colors.variants.primaryDark};
          background-image: ${color === 'default'
            ? `url('/chevron-down-dark.svg')`
            : `url('/triangle-down.svg')`};
          background-repeat: no-repeat;
          background-position: calc(100% - 1.25rem) center;
          color: ${error
            ? theme.colors.variants.neutralLight4
            : color === 'default'
            ? theme.colors.variants.neutralDark4
            : theme.colors.variants.neutralLight4};
          font-size: 0.75rem;
          font-weight: 500;
          padding: 0 1.5rem;
        `
      case 'ibe':
        return css`
          height: 3.75rem;
          background: url('/chevrons-down.svg') no-repeat calc(100% - 1.25rem);
          color: ${theme.colors.variants.neutralLight4};
          font-size: 0.9375rem;
          line-height: 1.4375rem;
          padding: 0 3.125rem 0 1.25rem;
        `
    }
  }}
`

interface ContainerProps {
  color: Color
  error: boolean
  isDirty: boolean
  variant: Variant
}

export type Color = 'default' | 'dark'
export type Variant = 'default' | 'ibe'
