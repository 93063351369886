import styled from '@emotion/styled'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

export interface Props {
  address?: string
  email?: string
  languageCode: string
  phone?: string
}

export const Contacts = memo(function Contacts({
  address,
  email,
  languageCode,
  phone,
}: Props) {
  return (
    <Container>
      {address ? <Address>{address}</Address> : null}
      {phone ? (
        <>
          <Divider />
          <Anchor
            href={`tel:${phone}`}
            onClick={() => {
              if (isMobile) {
                typeof window.gtag !== 'undefined' &&
                  window.gtag('event', 'Click', {
                    event_category: 'Website',
                    event_label: 'Phone Number',
                  })
              }
            }}
          >
            {`${useVocabularyData('ph', languageCode)}: ${phone}`}
          </Anchor>
        </>
      ) : null}
      {email ? (
        <>
          <Divider />
          <Anchor
            href={`mailto:${email}`}
            onClick={() => {
              typeof window.gtag !== 'undefined' &&
                window.gtag('event', 'Click', {
                  event_category: 'Website',
                  event_label: 'Email Address',
                })
            }}
          >
            {`${useVocabularyData('email', languageCode)}: ${email}`}
          </Anchor>
        </>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  margin-top: 3.75rem;

  @media (max-width: 1023px) {
    margin-top: 3.125rem;
  }
`

const Address = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
`

const Divider = styled.div``

const Anchor = styled.a`
  display: inline-block;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  margin-top: 0.6875rem;
  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 1023px) {
    font-size: 1rem;
    line-height: 1rem;
    margin-top: 0.75rem;
  }
`
