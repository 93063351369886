import styled from '@emotion/styled'
import { Facebook, Instagram, Linkedin, Youtube } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  facebookURL?: string
  instagramURL?: string
  youtubeURL?: string
  linkedinURL?: string
}

export const Social = memo(function Social({
  facebookURL,
  instagramURL,
  youtubeURL,
  linkedinURL,
}: Props) {
  if (!facebookURL && !instagramURL && !youtubeURL && !linkedinURL) {
    return null
  }

  return (
    <Container dial={3} row wrap>
      {facebookURL ? (
        <Anchor
          aria-label="Facebook"
          href={facebookURL}
          rel="noreferrer"
          target="_blank"
        >
          <Facebook />
        </Anchor>
      ) : null}
      {instagramURL ? (
        <Anchor
          aria-label="Instagram"
          href={instagramURL}
          rel="noreferrer"
          target="_blank"
        >
          <Instagram />
        </Anchor>
      ) : null}
      {youtubeURL ? (
        <Anchor
          aria-label="YouTube"
          className="youtube"
          href={youtubeURL}
          rel="noreferrer"
          target="_blank"
        >
          <Youtube />
        </Anchor>
      ) : null}
      {linkedinURL ? (
        <Anchor
          aria-label="LinkedIn"
          href={linkedinURL}
          rel="noreferrer"
          target="_blank"
        >
          <Linkedin />
        </Anchor>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-top: 6.25rem;

  @media (max-width: 1023px) {
    margin: 3.125rem auto 6.25rem;
  }
`

const Anchor = styled.a`
  display: flex;
  align-items: center;
  height: 1.25rem;
  margin-right: 1.5rem;
  &:last-of-type {
    margin-right: 0;
  }
  &.youtube {
    svg {
      height: 140%;
    }
  }
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.variants.secondaryLight};
    }
  }

  svg {
    width: auto;
    height: 100%;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    transition: 0.15s ease-in-out;
  }
`
